:root {
  --en-body-l-bold-font-family: "Noto Sans", Helvetica;
  --en-body-l-bold-font-size: 16px;
  --en-body-l-bold-font-style: normal;
  --en-body-l-bold-font-weight: 700;
  --en-body-l-bold-letter-spacing: 0.08px;
  --en-body-l-bold-line-height: normal;
  --en-body-l-medium-font-family: "Noto Sans", Helvetica;
  --en-body-l-medium-font-size: 16px;
  --en-body-l-medium-font-style: normal;
  --en-body-l-medium-font-weight: 500;
  --en-body-l-medium-letter-spacing: 0.08px;
  --en-body-l-medium-line-height: normal;
  --en-body-l-regular-font-family: "Noto Sans", Helvetica;
  --en-body-l-regular-font-size: 16px;
  --en-body-l-regular-font-style: normal;
  --en-body-l-regular-font-weight: 400;
  --en-body-l-regular-letter-spacing: 0.08px;
  --en-body-l-regular-line-height: normal;
  --en-body-m-bold-font-family: "Noto Sans", Helvetica;
  --en-body-m-bold-font-size: 14px;
  --en-body-m-bold-font-style: normal;
  --en-body-m-bold-font-weight: 700;
  --en-body-m-bold-letter-spacing: 0.07px;
  --en-body-m-bold-line-height: normal;
  --en-body-m-medium-font-family: "Noto Sans", Helvetica;
  --en-body-m-medium-font-size: 14px;
  --en-body-m-medium-font-style: normal;
  --en-body-m-medium-font-weight: 500;
  --en-body-m-medium-letter-spacing: 0.07px;
  --en-body-m-medium-line-height: normal;
  --en-body-m-regular-font-family: "Noto Sans", Helvetica;
  --en-body-m-regular-font-size: 14px;
  --en-body-m-regular-font-style: normal;
  --en-body-m-regular-font-weight: 400;
  --en-body-m-regular-letter-spacing: 0.07px;
  --en-body-m-regular-line-height: normal;
  --en-body-m-regular-link-font-family: "Noto Sans", Helvetica;
  --en-body-m-regular-link-font-size: 14px;
  --en-body-m-regular-link-font-style: normal;
  --en-body-m-regular-link-font-weight: 400;
  --en-body-m-regular-link-letter-spacing: 0.07px;
  --en-body-m-regular-link-line-height: normal;
  --en-caption-medium-font-family: "Noto Sans", Helvetica;
  --en-caption-medium-font-size: 12px;
  --en-caption-medium-font-style: normal;
  --en-caption-medium-font-weight: 500;
  --en-caption-medium-letter-spacing: 0.06px;
  --en-caption-medium-line-height: normal;
  --en-caption-regular-font-family: "Noto Sans", Helvetica;
  --en-caption-regular-font-size: 12px;
  --en-caption-regular-font-style: normal;
  --en-caption-regular-font-weight: 400;
  --en-caption-regular-letter-spacing: 0.06px;
  --en-caption-regular-line-height: normal;
  --en-caption-regular-link-font-family: "Noto Sans", Helvetica;
  --en-caption-regular-link-font-size: 12px;
  --en-caption-regular-link-font-style: normal;
  --en-caption-regular-link-font-weight: 400;
  --en-caption-regular-link-letter-spacing: 0.06px;
  --en-caption-regular-link-line-height: normal;
  --en-h1-bold-font-family: "Noto Sans", Helvetica;
  --en-h1-bold-font-size: 32px;
  --en-h1-bold-font-style: normal;
  --en-h1-bold-font-weight: 700;
  --en-h1-bold-letter-spacing: 0.16px;
  --en-h1-bold-line-height: normal;
  --en-h1-extralight-font-family: "Noto Sans", Helvetica;
  --en-h1-extralight-font-size: 32px;
  --en-h1-extralight-font-style: normal;
  --en-h1-extralight-font-weight: 200;
  --en-h1-extralight-letter-spacing: 0.16px;
  --en-h1-extralight-line-height: normal;
  --en-h1-light-font-family: "Noto Sans", Helvetica;
  --en-h1-light-font-size: 32px;
  --en-h1-light-font-style: normal;
  --en-h1-light-font-weight: 300;
  --en-h1-light-letter-spacing: 0.16px;
  --en-h1-light-line-height: normal;
  --en-h1-medium-font-family: "Noto Sans", Helvetica;
  --en-h1-medium-font-size: 32px;
  --en-h1-medium-font-style: normal;
  --en-h1-medium-font-weight: 500;
  --en-h1-medium-letter-spacing: 0.16px;
  --en-h1-medium-line-height: normal;
  --en-h2-bold-font-family: "Noto Sans", Helvetica;
  --en-h2-bold-font-size: 24px;
  --en-h2-bold-font-style: normal;
  --en-h2-bold-font-weight: 700;
  --en-h2-bold-letter-spacing: 0.12px;
  --en-h2-bold-line-height: normal;
  --en-h2-medium-font-family: "Noto Sans", Helvetica;
  --en-h2-medium-font-size: 24px;
  --en-h2-medium-font-style: normal;
  --en-h2-medium-font-weight: 500;
  --en-h2-medium-letter-spacing: 0.12px;
  --en-h2-medium-line-height: normal;
  --en-h2-regular-font-family: "Noto Sans", Helvetica;
  --en-h2-regular-font-size: 24px;
  --en-h2-regular-font-style: normal;
  --en-h2-regular-font-weight: 400;
  --en-h2-regular-letter-spacing: 0.12px;
  --en-h2-regular-line-height: normal;
  --TC-body-l-bold-font-family: "Noto Sans TC", Helvetica;
  --TC-body-l-bold-font-size: 16px;
  --TC-body-l-bold-font-style: normal;
  --TC-body-l-bold-font-weight: 700;
  --TC-body-l-bold-letter-spacing: 0.08px;
  --TC-body-l-bold-line-height: normal;
  --TC-body-l-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-body-l-medium-font-size: 16px;
  --TC-body-l-medium-font-style: normal;
  --TC-body-l-medium-font-weight: 500;
  --TC-body-l-medium-letter-spacing: 0.08px;
  --TC-body-l-medium-line-height: normal;
  --TC-body-l-regular-font-family: "Noto Sans TC", Helvetica;
  --TC-body-l-regular-font-size: 16px;
  --TC-body-l-regular-font-style: normal;
  --TC-body-l-regular-font-weight: 400;
  --TC-body-l-regular-letter-spacing: 0.08px;
  --TC-body-l-regular-line-height: normal;
  --TC-body-l-regular-link-font-family: "Noto Sans TC", Helvetica;
  --TC-body-l-regular-link-font-size: 16px;
  --TC-body-l-regular-link-font-style: normal;
  --TC-body-l-regular-link-font-weight: 400;
  --TC-body-l-regular-link-letter-spacing: 0.08px;
  --TC-body-l-regular-link-line-height: normal;
  --TC-body-m-bold-font-family: "Noto Sans TC", Helvetica;
  --TC-body-m-bold-font-size: 14px;
  --TC-body-m-bold-font-style: normal;
  --TC-body-m-bold-font-weight: 700;
  --TC-body-m-bold-letter-spacing: 0.07px;
  --TC-body-m-bold-line-height: normal;
  --TC-body-m-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-body-m-medium-font-size: 14px;
  --TC-body-m-medium-font-style: normal;
  --TC-body-m-medium-font-weight: 500;
  --TC-body-m-medium-letter-spacing: 0.07px;
  --TC-body-m-medium-line-height: normal;
  --TC-body-m-regular-font-family: "Noto Sans TC", Helvetica;
  --TC-body-m-regular-font-size: 14px;
  --TC-body-m-regular-font-style: normal;
  --TC-body-m-regular-font-weight: 400;
  --TC-body-m-regular-letter-spacing: 0.07px;
  --TC-body-m-regular-line-height: normal;
  --TC-body-m-regular-link-font-family: "Noto Sans TC", Helvetica;
  --TC-body-m-regular-link-font-size: 14px;
  --TC-body-m-regular-link-font-style: normal;
  --TC-body-m-regular-link-font-weight: 400;
  --TC-body-m-regular-link-letter-spacing: 0.07px;
  --TC-body-m-regular-link-line-height: normal;
  --TC-caption-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-caption-medium-font-size: 12px;
  --TC-caption-medium-font-style: normal;
  --TC-caption-medium-font-weight: 500;
  --TC-caption-medium-letter-spacing: 0.06px;
  --TC-caption-medium-line-height: normal;
  --TC-caption-regular-font-family: "Noto Sans TC", Helvetica;
  --TC-caption-regular-font-size: 12px;
  --TC-caption-regular-font-style: normal;
  --TC-caption-regular-font-weight: 400;
  --TC-caption-regular-letter-spacing: 0.06px;
  --TC-caption-regular-line-height: normal;
  --TC-caption-regular-link-font-family: "Noto Sans TC", Helvetica;
  --TC-caption-regular-link-font-size: 12px;
  --TC-caption-regular-link-font-style: normal;
  --TC-caption-regular-link-font-weight: 400;
  --TC-caption-regular-link-letter-spacing: 0.06px;
  --TC-caption-regular-link-line-height: normal;
  --TC-h1-bold-font-family: "Noto Sans TC", Helvetica;
  --TC-h1-bold-font-size: 32px;
  --TC-h1-bold-font-style: normal;
  --TC-h1-bold-font-weight: 700;
  --TC-h1-bold-letter-spacing: 0.16px;
  --TC-h1-bold-line-height: normal;
  --TC-h1-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-h1-medium-font-size: 32px;
  --TC-h1-medium-font-style: normal;
  --TC-h1-medium-font-weight: 500;
  --TC-h1-medium-letter-spacing: 0.16px;
  --TC-h1-medium-line-height: normal;
  --TC-h2-bold-font-family: "Noto Sans TC", Helvetica;
  --TC-h2-bold-font-size: 24px;
  --TC-h2-bold-font-style: normal;
  --TC-h2-bold-font-weight: 700;
  --TC-h2-bold-letter-spacing: 0.12px;
  --TC-h2-bold-line-height: normal;
  --TC-h2-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-h2-medium-font-size: 24px;
  --TC-h2-medium-font-style: normal;
  --TC-h2-medium-font-weight: 500;
  --TC-h2-medium-letter-spacing: 0.12px;
  --TC-h2-medium-line-height: normal;
  --TC-h2-regular-font-family: "Noto Sans TC", Helvetica;
  --TC-h2-regular-font-size: 24px;
  --TC-h2-regular-font-style: normal;
  --TC-h2-regular-font-weight: 400;
  --TC-h2-regular-letter-spacing: 0.12px;
  --TC-h2-regular-line-height: normal;
  --TC-tag-medium-font-family: "Noto Sans TC", Helvetica;
  --TC-tag-medium-font-size: 10px;
  --TC-tag-medium-font-style: normal;
  --TC-tag-medium-font-weight: 500;
  --TC-tag-medium-letter-spacing: 0.05px;
  --TC-tag-medium-line-height: normal;
  --TC-tag-regular-font-family: "Noto Sans TC", Helvetica;
  --TC-tag-regular-font-size: 10px;
  --TC-tag-regular-font-style: normal;
  --TC-tag-regular-font-weight: 400;
  --TC-tag-regular-letter-spacing: 0.05px;
  --TC-tag-regular-line-height: normal;
  --variable-collection-highlight-blue: rgba(162, 227, 243, 1);
  --variable-collection-highlight-dark-blue: rgba(121, 141, 231, 1);
  --variable-collection-highlight-green: rgba(196, 251, 39, 1);
  --variable-collection-highlight-yellow: rgba(251, 255, 32, 1);
  --variable-collection-primary-default: rgba(1, 0, 140, 1);
  --variable-collection-secondary-blue: rgba(68, 167, 192, 1);
  --variable-collection-secondary-dark-blue: rgba(44, 53, 93, 1);
  --variable-collection-secondary-green: rgba(177, 227, 34, 1);
  --variable-collection-secondary-yellow: rgba(232, 236, 29, 1);
  --variable-collection-text-disable: rgba(163, 164, 166, 1);
  --variable-collection-text-disable-white: rgba(119, 119, 119, 1);
  --variable-collection-text-primary: rgba(49, 50, 51, 1);
  --variable-collection-text-primary-white: rgba(255, 255, 255, 1);
  --variable-collection-text-secondary: rgba(83, 84, 87, 1);
  --variable-collection-text-secondary-white: rgba(189, 189, 189, 1);
  --variable-collection-text-tertiary: rgba(117, 119, 122, 1);
  --variable-collection-text-tertiary-white: rgba(136, 138, 141, 1);
}
